import React, {useEffect, useState} from 'react';
import {Button, Col, Popover, Row, Tooltip} from 'antd';
import {useSelector} from 'react-redux';
import IntlMessages from '@iso/components/utility/intlMessages';
import TopbarDropdownWrapper from './TopbarDropdown.styles';
import {getUserNotifications, markAsRead} from "../../../services/notifications/notifications.service";
import * as N from "./styles";
import {useHistory} from "react-router-dom";
import parse from 'html-react-parser';

export default function TopbarNotification() {
  const [visible, setVisiblity] = React.useState(false);
  const [notifications, setNotifications] = useState([]);
  const customizedTheme = useSelector(
    (state) => state.ThemeSwitcher.topbarTheme
  );
  const history = useHistory();

  const iconStatus = (notification) => {
    switch (notification.type) {
      case 'cash_conciliation_approved':
        return <N.CheckIcon/>;
      case 'cash_conciliation_refused':
        return <N.DisapprovedIcon/>;
      default:
        return null;
    }
  };
  useEffect(() => {
    getNotifications()
  }, []);

  const getNotifications = async () => {
    const response = await getUserNotifications();
    const filteredNotifications = response.data.data.filter(notification =>
      notification.type !== 'cash_conciliation_reject_solicitation');

    setNotifications(filteredNotifications);
  };

  const handleClickNotification = async (notification) => {
    await markAsRead(notification.id);
    getNotifications();
  }

  function handleVisibleChange() {
    setVisiblity((visible) => !visible);
  }

  const content = (
    <TopbarDropdownWrapper className="topbarNotification">
      <div className="isoDropdownHeader">
        <h3>
          <IntlMessages id='Notificações'/>
        </h3>
      </div>
      <div className="isoDropdownBody">
        {notifications.length === 0 ? (
          <N.CenterDiv>
            <h5>Nada por aqui.</h5>
          </N.CenterDiv>
        ) : (
          notifications.map((notification) => (
              <a className="isoDropdownListItem">
                <Row>
                  <Col span={3}>
                    {iconStatus(notification)}
                  </Col>
                  <Col span={19}>
                    <h5>{parse(notification.message)}</h5>
                  </Col>
                  <Col span={2}>
                    <Tooltip title='Excluir notificação'>
                      <Button type='link' onClick={() => handleClickNotification(notification)}>
                        <N.XIcon/>
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              </a>
            )
          )
        )}
      </div>
    </TopbarDropdownWrapper>
  );
  return (
    <Popover
      content={content}
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      placement="bottomLeft"
    >
      <div className="isoIconWrapper">
        <i
          className="ion-android-notifications"
          style={{color: customizedTheme.textColor}}
        />
        <span>{notifications.length}</span>
      </div>
    </Popover>
  );
}
