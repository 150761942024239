import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import options from './options';
import Scrollbars from '@iso/components/utility/customScrollBar';
import Menu from '@iso/components/uielements/menu';
import appActions from '@iso/redux/app/actions';
import Logo from '@components/Layout/Logo/Logo';
import SidebarWrapper from './Sidebar.styles';
import styled from "styled-components";
import { WechatOutlined } from '@ant-design/icons';
const { Sider } = Layout;

const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } =
  appActions;

const stripTrailingSlash = (str) => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};

const RedStripDiv = styled.div`
  padding: 10px;
  border: 1px solid;
  background-color: ${process.env.REACT_APP_STRIP_COLOR};
  text-align: center;
  border-radius: 2px;
`

const RedStripSpan = styled.div`
  color: #000000;
  font-weight: bold;
`

const SupportDiv = styled.div`
  position: absolute;
  bottom: 0; 
  left: 0; 
  width: 250px;
  padding: 16px; 
  background-color: #1F2431;
`

const TextHolder = styled.span`
  color: white;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WechatOutlinedStyled = styled(WechatOutlined)`
  font-size: 16px;
  color: #ffffff;
`;

export default function Sidebar() {
  let match = useRouteMatch();
  const url = stripTrailingSlash(match.url);

  const dispatch = useDispatch();
  const { view, openKeys, collapsed, openDrawer, current, height } =
    useSelector((state) => state.App);
  const customizedTheme = useSelector(
    (state) => state.ThemeSwitcher.sidebarTheme
  );
  const user = useSelector(state => state.auth.user);

  function handleClick(e) {
    dispatch(changeCurrent([e.key]));
    if (view === 'MobileView') {
      setTimeout(() => {
        dispatch(toggleCollapsed());
        // dispatch(toggleOpenDrawer());
      }, 100);

      // clearTimeout(timer);
    }
  }
  function onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find(
      (key) => !(openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = openKeys.find(
      (key) => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    dispatch(changeOpenKeys(nextOpenKeys));
  }
  const getAncestorKeys = (key) => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? 'vertical' : 'inline';
  const onMouseEnter = (event) => {
    if (collapsed && openDrawer === false) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const onMouseLeave = () => {
    if (collapsed && openDrawer === true) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const styling = {
    backgroundColor: customizedTheme.backgroundColor,
  };
  const submenuColor = {
    color: customizedTheme.textColor,
  };

  const sidebarItems = options.map(({ key, label, leftIcon, children, ability}) => {
    if (
      !(ability.some(requiredAbility => user.abilities.some(a => a.ability === requiredAbility)) ||
        user.isAdmin)
    ) {
      return null;
    }

    if (children) {
      return {
        key,
        label: (
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              {label}
            </span>
          </span>
        ),
        children: children.map((child) => {
          if (
            !(child.ability.some(requiredAbility => user.abilities.some(a => a.ability === requiredAbility)) ||
              user.isAdmin)
          ) {
            return null;
          }

          const linkTo = child.withoutDashboard
            ? `/${child.key}`
            : `/${child.key}`;
          return {
            key: child.key,
            label: (
              <Link style={submenuColor} to={linkTo}>
                {child.label}
              </Link>
            ),
          };
        }),
      };
    }
    return {
      key,
      label: (
        <Link to={`/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            <i className={leftIcon} />
            <span className="nav-text">
              {label}
            </span>
          </span>
        </Link>
      ),
    };
  });

  const environment = process.env.REACT_APP_SPARTACUS_ENVIRONMENT;
  const movidesk = process.env.REACT_APP_MOVIDESK_TICKET_URL;
  const stripColor = process.env.REACT_APP_SPARTACUS_STRIP_COLOR;

  return (
    <SidebarWrapper>
      <Sider
        trigger={null}
        collapsible={true}
        collapsed={isCollapsed}
        width={240}
        className="isomorphicSidebar"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={styling}
      >
        <Logo collapsed={isCollapsed} />
        {
          environment !== 'production' &&
            <RedStripDiv>
              <RedStripSpan>
                {environment.toString().toUpperCase()}
              </RedStripSpan>
            </RedStripDiv>
        }
        <Scrollbars style={{ height: height - 70 }}>
          <Menu
            items={sidebarItems}
            onClick={handleClick}
            theme="dark"
            className="isoDashboardMenu"
            mode={mode}
            openKeys={isCollapsed ? [] : openKeys}
            selectedKeys={current}
            onOpenChange={onOpenChange}
          />
          <SupportDiv>
            <a href={movidesk} target="_blank" rel="noopener noreferrer">
              <TextHolder className="isoMenuHolder" >
                <WechatOutlinedStyled className="nav-text" />
                Abrir chamado
              </TextHolder>
            </a>
          </SupportDiv>
        </Scrollbars>
      </Sider>
    </SidebarWrapper>
  );
}
