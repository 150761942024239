export default {
  siteName: 'SPARTACUS',
  siteIcon: 'ion-flash',
  footerText: `Spartacus @ ${new Date().getFullYear()} by Grupo Trapézio`,
  enableAnimatedRoute: false,
  apiUrl: 'http://yoursite.com/api/',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};
