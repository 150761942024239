import styled from 'styled-components';
import {BiCheckSquare, BiErrorCircle, BiX} from 'react-icons/all';

export const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const CheckIcon = styled(BiCheckSquare)`
  font-size: 25px;
  color: green;
  vertical-align: middle;
`;

export const XIcon = styled(BiX)`
  font-size: 25px;
  color: black;
  vertical-align: middle;
`;

export const DisapprovedIcon = styled(BiErrorCircle)`
  font-size: 25px;
  color: red;
  vertical-align: middle;
`;

export const ListNotificationsDiv = styled.div`
  padding: 15px 30px;
  flex-shrink: 0;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 100%;
`;




