import { configureStore } from '@reduxjs/toolkit';
import themeRootSaga from '@iso/redux/root-saga';
import createSagaMiddleware from 'redux-saga';

import App from '@iso/redux/app/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import auth from '@stores/reducers/auth.reducer';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    App,
    ThemeSwitcher,
    LanguageSwitcher,
    auth,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware)
});

// const store = createStore(rootReducer, bindMiddleware(middlewares));
sagaMiddleware.run(themeRootSaga);
export { store };
