import axios from 'axios';
import {store} from '@stores';
import { notification } from 'antd';
import { createBrowserHistory } from 'history';

const customHistory = createBrowserHistory();
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.interceptors.request.use(
  config => {

    const {auth, isLoggedIn} = store.getState().auth;

    if (!isLoggedIn || !auth.access_token || !auth.token_type) {
      return config;
    }

    if (!config.headers.Authorization) {
      config.headers.Authorization = `${auth.token_type} ${auth.access_token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

      const { tryingLogin } = store.getState().auth;

      if (error.response.status === 401 && !tryingLogin) {
          localStorage.removeItem('@auth:user');
          customHistory.push('/signin');
          window.location.reload();
          return;
      }

        if (showErrorNotification) {
          notification.error({
            message: 'Erro desconhecido.',
            description: error.response.data.message || 'Ocorreu um erro desconhecido.'
          });
        }

    return Promise.reject(error);
  }
);

let showErrorNotification = true;

export const setShowErrorNotification = (value) => {
  showErrorNotification = value;
};

export default api;