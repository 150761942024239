import React from 'react';
import LayoutWrapper from '@components/Layout/Content/LayoutWrapper';
import LayoutContent from '../../components/Layout/Content/LayoutContent';

export default function NotAllowedScreen() {
  return (
    <LayoutWrapper>
      <LayoutContent>
        <h1>{'Você não tem permissão para acessar esta página'}</h1>
      </LayoutContent>
    </LayoutWrapper>
  );
}
