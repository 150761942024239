import React from 'react';
import { Link } from 'react-router-dom';
import appConfig from '@config/app.config';

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/">
              <img
                src={require('../../../assets/images/logo-spartacus.png')}
                style={{width: 35, height: 35}}
                alt="Sidebar Icon"
              />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/">{appConfig.siteName}</Link>
        </h3>
      )}
    </div>
  );
};
