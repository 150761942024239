import React, { useEffect } from "react";
import {Provider, useDispatch} from "react-redux";
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from '@stores';
import Boot from '@iso/redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import {getCredentials} from '@stores/reducers/auth.reducer';
import { GlobalAlertProvider } from "@contexts/GlobalAlertContext";

const AppContent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCredentials());
  }, [])

  return (
    <>
      <GlobalStyles />
      <GlobalAlertProvider>
        <Routes />
      </GlobalAlertProvider>
    </>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <AppProvider>
        <AppContent />
      </AppProvider>
    </Provider>
  );
}
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
