import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'antd';
import useWindowSize from '@iso/lib/hooks/useWindowSize';
import appActions from '@iso/redux/app/actions';
import ThemeSwitcher from '@iso/containers/ThemeSwitcher/ThemeSwitcher';
import appConfig from '@config/app.config';
import Sidebar from '@components/Layout/Sidebar/Sidebar';
import Topbar from '@components/Layout/Topbar/Topbar';

import { WrapperContainer, WrapperGlobalStyles } from './LayoutWrapper.styles';
import { LayoutContentWrapper } from './LayoutWrapper.styles';

const { Content, Footer } = Layout;
const { toggleAll } = appActions;
const styles = {
  layout: { flexDirection: 'row', overflowX: 'hidden' },
  content: {
    padding: '70px 0 0',
    flexShrink: '0',
    background: '#f1f3f6',
    position: 'relative',
  },
  footer: {
    background: '#ffffff',
    textAlign: 'center',
    borderTop: '1px solid #ededed',
  },
};

const LayoutContent = props => {
  return <LayoutContentWrapper
    className={
      props.className != null
        ? `${props.className} isoLayoutContentWrapper`
        : 'isoLayoutContentWrapper'
    }
    {...props}
  >
    {props.children}
  </LayoutContentWrapper>
}

const LayoutWrapper = ({children}) => {
  const dispatch = useDispatch();
  const appHeight = useSelector((state) => state.App.height);
  const { width, height } = useWindowSize();

  React.useEffect(() => {
    dispatch(toggleAll(width, height));
  }, [width, height, dispatch]);
  return (
    <WrapperContainer>
      <WrapperGlobalStyles />
      <Layout style={{ height: height }}>
        <Topbar />
        <Layout style={styles.layout}>
          <Sidebar />
          <Layout
            className="isoContentMainLayout"
            style={{
              height: appHeight,
            }}
          >
            <Content className="isomorphicContent" style={styles.content}>
              <LayoutContent>
                {children}
              </LayoutContent>
            </Content>
            <Footer style={styles.footer}>{appConfig.footerText}</Footer>
          </Layout>
        </Layout>
      </Layout>
    </WrapperContainer>
  );
}

export default LayoutWrapper;
